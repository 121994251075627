/**
 * This selector returns enriched iclr objects for all iclrs that are connected to classSessions in the course
*/
import { createSelector } from '@reduxjs/toolkit';
import { ClassSessionIclrApi } from 'types/backend/classSessionIclr.types';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { IclrApi } from 'types/backend/iclr.types';
import { OoclrApi } from 'types/backend/ooclr.types';
import { EnrichedClassSessionClr, InstructionEnum } from 'types/common.types';
import { Store } from 'types/store.types';

const appUrl = process.env.REACT_APP_URL || 'http://localhost:3000';

export const enrichClassSessionClrs = (
  classSessionClrs: Array<ClassSessionIclrApi | ClassSessionOoclrApi>,
  clrs: Array<IclrApi | OoclrApi>,
  clrType: InstructionEnum
) => {
  if (!classSessionClrs.length || !clrs.length) {
    return [];
  }
  return classSessionClrs.map((csClr) => {
    // if it's not iclrId, it's going to be ooclrId
    const clrId = (csClr as ClassSessionIclrApi).iclrId || (csClr as ClassSessionOoclrApi).ooclrId;
    const { id, title, url, type, userId } = clrs.find((clr) => clr.id === clrId) as IclrApi;
    const classSessionIds = classSessionClrs.reduce((acc: Array<number>, cur) => {
      const { classSessionId } = cur;
      const { iclrId } = cur as ClassSessionIclrApi;
      const { ooclrId } = cur as ClassSessionOoclrApi;
      const csClrId = iclrId || ooclrId;
      return csClrId === id ? [...acc, classSessionId] : acc;
    }, []);
    // If linking to another page within the webapp, add an attribute so we know not to open in a new tab
    const internalLink = !!url && url.includes(appUrl);
    const enrichedIclr: EnrichedClassSessionClr = {
      ...csClr,
      classSessionIds,
      clrId,
      clrType,
      internalLink,
      title,
      url,
      type,
      userId,
    };
    return enrichedIclr;
  });

};

export default createSelector(
  (store: Store) => store.active.classSessionIclrs,
  (store: Store) => store.active.iclrs,
  (classSessionIclrs: Array<ClassSessionIclrApi> = [], iclrs: Array<IclrApi> = []) => {
    return enrichClassSessionClrs(classSessionIclrs, iclrs, InstructionEnum.InClass);
  }
);
