/**
* The purpose of this types file is to store the common types that are ONLY used on the frontend
* e.g. enhanced versions of the backend types, inter-component common types
*/
import { ReactNode } from 'react';
import { AssessmentQuestionApi } from './backend/assessmentQuestions.types';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { ClarityEnum, LibraryTypeEnum, YesNo } from 'types/backend/shared.types';

// whenever we need to refer to an `event.key` value or event type, add it to the enums below
export enum EventKeyEnum {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
  Escape = 'Escape',
  Space = 'Space',
}

export enum EventKeyTypeEnum {
  KeyUp = 'keyup',
}

export enum AssessmentStatus {
  Published = 'Published',
  Unpublished = 'Unpublished',
  Started = 'Started'
}

export enum MultipleAttemptPolicyEnum {
  CorrectnessTypeOne = '1',
  CorrectnessTypeTwo = '2',
  CorrectnessTypeThree = '3',
  CorrectnessTypeFour = '4',
  ForCompletion = '5',
  NotForPoints = '6',
  NotFound = '-1'
}

export interface SimpleEnrichedAQM extends AssessmentQuestionApi {
  assessmentNumber: number
  questionNumber: number
}

// differentiating mapped from enhanced
export interface MappedAssessmentQuestion extends AssessmentQuestionApi {
  assessmentIds?: Array<string>
  _derived: {
    assessmentData: AssessmentApiBase
    classSessionIds: Array<number>
    courseLearningObjectives: Array<EnrichedCourseLearningObjective>
    questionData: QuestionApiOut
  }
}

/**
 * enriched types
 */
// ClrData is a generic type for interchangeably dealing with Iclrs or Ooclrs, FKA Clr, was wrongly housed in api.types
export type ClrData = {
  title: string
  url: string | null
  type: LibraryTypeEnum
  userId?: string
  createdAt: string
  updatedAt: string
}
export interface EnrichedClassSessionClr extends ClrData {
  id: number
  classSessionId: number
  classSessionIds?: Array<number>
  clrId: number
  clrType: InstructionEnum
  internalLink: boolean
  order?: number
}

/**
 * Page enums
 */
export enum InstructionEnum {
  InClass = 'ic',
  OutOfClass = 'ooc',
}

export enum PortalEnum {
  ModalPortal = 'app-modal-portal',
  TooltipPortal = 'app-tooltip-portal',
  ModalTooltipPortal = 'app-modal-tooltip-portal',
}

export enum LogoutStatusEnum {
  IdleLogout = 'idle-logout',
  IdleLogoutLti = 'idle-logout-lti',
  LogoutLti = 'logout-lti',
}

export enum AccessStatusEnum {
  ActiveGracePeriod = 'active-grace-period',
  FirstAccess = 'first-access',
  Free = 'free',
  GracePeriodWarning = 'grace-period-warning',
  GracePeriodExpired = 'grace-period-expired',
  NotStarted = 'not-started',
  Paid = 'paid',
}

export enum DirectionEnum {
  Next = 'next',
  Prev = 'prev',
}

export enum AddRemoveEnum {
  Add = 'add',
  Remove = 'remove',
}

export enum LeftRightEnum {
  Left = 'left',
  Right = 'right',
}

export enum UpDownTopBottomEnum {
  Up = 'up',
  Down = 'down',
  Top = 'top',
  Bottom = 'bottom',
}

export enum ConfirmationTypeEnum {
  Warn = 'warn',
  Alert = 'alert',
}

/**
 * Hashes
 */
export interface AttemptsHash {
  [key: string]: number
}

export interface ClarityHash {
  [key: string]: ClarityEnum
}

export interface CorrectHash {
  [key: string]: YesNo
}

export interface PointsHash {
  [key: string]: number | null
}

export interface BooleanHash {
  [key: string]: boolean
}

export interface RecapHash {
  [key: string]: boolean
}

export interface VatFrozenHash {
  [key: string]: YesNo
}

export interface SurveyItemsHash {
  [key: string]: boolean
}

export interface StandardMenuItem {
  icon?: React.ReactNode
  label: string
  onClick?: () => void
  url?: string
  external?: boolean
  show?: boolean
}

export enum PositionEnum {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}

// these are the types for apiNext.createAssessment, userId gets set in apiNext so it is not necessary to pass in
export type CreateAssessmentBody = Omit<AssessmentApiBase, 'createdAt' | 'updatedAt' | 'userId' | 'id' | 'attemptsInAt' | 'lineItemId'>;
export type CreateSummativeAssessmentBody = CreateAssessmentBody & {
  prep: CreateAssessmentBody
  practiceTest: CreateAssessmentBody
}

export enum LoadingState {
  Init = 'init',
  Loading = 'loading',
  Loaded = 'loaded',
}

export enum ContentTypeEnum {
  LearningObjective = 'learning-objective',
  Question = 'question',
}

export type ChildrenProp = Array<ReactNode> | ReactNode
