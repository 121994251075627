export enum InstructorCoursePath {
  Edit = 'edit',
  CreateCourse = 'create-course',
  SelectLearningObjectives = 'selectlo',
  DailyPlanner = 'dailyplanner',
  Instructions = 'instructions',
  AssessmentBuilder = 'assessmentbuilder',
  AssessmentsList = 'assessmentslist',
  CoursePlanner = 'courseplanner',
  BetterCoursePlanner = 'plan',
  StudentPerformance = 'scores',
  DevUtils = 'devutils',
  AdminQADashboard = 'qadashboard',
  QuestionAuthor = 'question-author',
  CourseAccess = 'course-access',
  Poll = 'poll',
}
