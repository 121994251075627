import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { FaPencilAlt } from 'react-icons/fa';
import { DateTime } from 'luxon';

import useQuery from 'hooks/useQuery';
import apiNext, { useGetProductQuery } from 'api-next';
import CodonUrls from 'urls';
import sharedStrings from 'sharedStrings';
import { mailToLink } from 'utils/commonFormattingFunctions';
import { standardDateFormat } from 'utils/dateFormattingFunctions';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAppDispatch, useAppSelector } from 'store';
import activeSlice from 'store/slices/active';
import passiveSlice from 'store/slices/passive';
import retrieveCourseAccessData from 'store/selectors/retrieveCourseAccessData';
import loadUserEnrollments from 'store/actions/loadUserEnrollments';
import updateUserEnrollmentOnFirstAccess from 'store/actions/updateUserEnrollmentOnFirstAccess';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import ExternalLink from 'shared-components/ExternalLink/ExternalLink';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import TextButton from 'shared-components/BetterButton/TextButton';
import AccessCodeForm from './AccessCodeForm';
import { AccessCodeValidationResult } from './AccessController.types';
import { AccessStatusEnum, ConfirmationTypeEnum } from 'types/common.types';
import { StudentCoursePath } from 'types/student.types';
import { RoleEnum } from 'types/backend/roles.types';
import { YesNo } from 'types/backend/shared.types';
import './AccessController.scss';

export default function AccessController() {
  useDocumentTitle('Course Access');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [, setGraceWarningDismissed] = useLocalStorage<number | null>('GRACE_WARNING_DISMISSED', null);
  const course = useAppSelector((store) => store.active.course);
  const userEmail = useAppSelector((store) => store.user.email);
  const courseId = course?.id;
  const enrollment = useAppSelector((store) => store.active.enrollment);
  const courseAccessData = useAppSelector(retrieveCourseAccessData);
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const { data: product } = useGetProductQuery(course.productId, { skip: !course, refetchOnMountOrArgChange: true });

  const query = useQuery();
  const reqPathFromQuery = query.get('reqPath');
  const [showAccessCodeInput, setShowAccessCodeInput] = useState(false);
  const [showAccessPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [accessInstructions, setAccessInstructions] = useState(course.accessInstructions || '');
  const [isEditing, setIsEditing] = useState(false);
  const isEnrolledInstructor = enrollment.roleId === RoleEnum.Instructor;

  async function handlePayment() {
    if (isEnrolledInstructor) {
      triggerConfirmationPrompt({
        title: 'Payment Not Active',
        message: 'Payment is not active for instructors. This button redirects students to pay securely via Stripe.',
        onConfirm: () => {},
        confirmationType: ConfirmationTypeEnum.Warn,
      });
      return;
    }
    const result = await apiNext.launchPayment(enrollment.id);
    await dispatch(loadUserEnrollments(enrollment.userId));
    window.location.href = result.url;
  }

  async function submitAccessCode(code: string): Promise<AccessCodeValidationResult> {
    try {
      const result = await apiNext.validateAccessCode(code, enrollment.id);
      await dispatch(loadUserEnrollments(enrollment.userId));
      return { success: true, message: result };
    } catch (err: any) {
      //TODO error messaging handling improvements
      console.error('returned result', err.response);
      return { success: false, message: err.response.data.message };
    }
  }

  async function saveCourseAccessInstructions() {
    const updatedCourse = await apiNext.editCourse(course.id, {
      ...course,
      accessInstructions: accessInstructions || null,
    });
    dispatch(activeSlice.actions.setActiveCourse(updatedCourse));
    dispatch(passiveSlice.actions.updatePassiveInstructorCourse(updatedCourse));
    setIsEditing(false);
  }

  function cancelCourseAccessInstructions() {
    setAccessInstructions(course.accessInstructions || '');
    setIsEditing(false);
  }

  if (!courseAccessData || !course.id) {
    return <div>no course access data</div>;
  }
  const { accessStatus, accessDateStarted } = courseAccessData;
  const fullAccess = [AccessStatusEnum.Free, AccessStatusEnum.Paid].includes(accessStatus);

  async function handleSubmitAccessCode(accessCode: string) {
    const requestResult: AccessCodeValidationResult = await submitAccessCode(accessCode);
    if (requestResult.success) {
      setShowPaymentSuccess(true);
    }
    return requestResult;
  }

  const renderAccessInstructions = () => {
    return (
      <div className="access-container__access-instructions__display">
        {isEditing ? (
          <>
            <textarea
              className="access-container__access-instructions-input"
              name="accessInstructions"
              value={accessInstructions}
              onChange={e => setAccessInstructions(e.target.value)}
              rows={4}
            />
            <div className="access-container__access-instructions-buttons">
              <BetterButton
                primary
                onClick={saveCourseAccessInstructions}
                text="Save"
              />
              <TextButton onClick={cancelCourseAccessInstructions}>Cancel</TextButton>
            </div>
          </>
        ) : (
          <p>
            {isEnrolledInstructor ? (
              <>
                {accessInstructions || <em>Click to add additional access instructions.</em>}
                <button
                  className="edit-access-instructions"
                  title="Edit course access instructions"
                  onClick={() => setIsEditing(!isEditing)}
                >
                  <FaPencilAlt />
                </button>
              </>
            ) : accessInstructions}
          </p>
        )}
      </div>
    );
  };

  const renderInfoText = (accStat: AccessStatusEnum) => {
    switch (accStat) {
      case AccessStatusEnum.NotStarted:
        return (
          <p className="access-container__info-text">
            This course has not started yet. It will be available on {standardDateFormat(course.accessDate)}.
          </p>
        );
      case AccessStatusEnum.GracePeriodExpired:
        return (
          <>
            <p className="access-container__info-text">
              Your temporary free access period has ended.
            </p>
            {renderAccessInstructions()}
            <p className="access-container__info-text">
              What would you like to do?
            </p>
          </>
        );
      case AccessStatusEnum.ActiveGracePeriod:
      case AccessStatusEnum.GracePeriodWarning:
        return (
          <>
            <p className="access-container__info-text">
              You have temporary free access to this course. The last day of the temporary free access period is {standardDateFormat(course.graceEndDate)}.
            </p>
            {renderAccessInstructions()}
            <p className="access-container__info-text">
              What would you like to do?
            </p>
          </>
        );
      case AccessStatusEnum.Free:
      case AccessStatusEnum.Paid:
        return `You have full, ${accessStatus === AccessStatusEnum.Free ? 'free' : 'paid'} access to this course.`;
      default: return null;
    }
  };

  const linkIntoCourse = reqPathFromQuery || `/student/course/${courseId}/${StudentCoursePath.Home}`;

  const handlePayLater = async () => {
    if (courseAccessData && !enrollment.firstAccessedAt) {
      await dispatch(updateUserEnrollmentOnFirstAccess(enrollment.id));
    }
    if (courseAccessData.accessStatus === AccessStatusEnum.GracePeriodWarning) {
      setGraceWarningDismissed(+DateTime.now());
    }
    history.push(linkIntoCourse);
  };

  if (!product) {
    return (
      <LoadingSpinner
        id="product-loading"
        loadingMessage="Product information is loading."
        timeoutMessage={
          <>
            <br/>Try launching in a private/incognito window, try a different browser, or<br />
            follow the instructions in our <ExternalLink text="support article" url={CodonUrls.ClearCacheKB} /> to clear browser cache and cookies
          </>
        }
      />
    );
  }

  let payLaterButtonText = 'I will pay later';
  if (product.showCode === YesNo.Yes && product.showCC === YesNo.No) {
    payLaterButtonText = 'I will enter my code later';
  } else if (product.showCode === YesNo.Yes && product.showCC === YesNo.Yes) {
    payLaterButtonText = 'I will pay or enter my code later';
  }

  return (
    <main className="access-container" data-accessstatus={accessStatus}>
      {isEnrolledInstructor && (
        <div className="access-container__instructor-banner">
          You are previewing payment and access information for students.
          {!accessDateStarted && (
            <><br/>Students will not be able to view your course or the access information below until the Course Access Date of {standardDateFormat(course.accessDate)}.</>
          )}
        </div>
      )}
      <div className="access-container__main">
        <h2 className="h3">Course Access Info</h2>
        <div role="alert">
          {renderInfoText(accessStatus)}
        </div>
        {showAccessPaymentSuccess && (
          <>
            <div>
              Your access code was verified. Thank you for using Codon Learning! &nbsp;
            </div>
            <div className="access-code-result">
              <BetterButton
                className="primary access-code-form-button"
                text='Take me to my course'
                linkTo={linkIntoCourse}
              />
            </div>
          </>
        )}
        {!fullAccess && accessStatus !== AccessStatusEnum.NotStarted && (
          <div className="access_action-column">
            {showAccessCodeInput ? (
              <AccessCodeForm
                handleCancel={() => setShowAccessCodeInput(false)}
                submitAccessCode={handleSubmitAccessCode}
                isEnrolledInstructor={isEnrolledInstructor}
              />
            ) : (
              <>
                {accessStatus !== AccessStatusEnum.GracePeriodExpired && (
                  <BetterButton
                    primary
                    className="access-action__pay-later"
                    text={`${payLaterButtonText} - take me to my course`}
                    onClick={handlePayLater}
                  />
                )}
                {product.showCC === YesNo.Yes && (
                  <BetterButton
                    primary
                    className="access-action__pay-now"
                    text="I'm ready to pay now using a credit card"
                    onClick={handlePayment}
                  />
                )}
                {product.showCode === YesNo.Yes && (
                  <BetterButton
                    primary
                    className="access-action__enter-code"
                    text="I have an access code"
                    onClick={() => setShowAccessCodeInput(true)}
                  />
                )}
              </>
            )}
          </div>
        )}
        <div className="access-container-support">
          Contact us with questions at {mailToLink(sharedStrings.CODON_SUPPORT_EMAIL, sharedStrings.CODON_SUPPORT_EMAIL, `Course Access help for ${course.name}`)} or use the support link at the top.
          <br/>
          <div className="access-container-support__subtext">
            Please include your account email address <strong>{userEmail}</strong> to help us assist you.
          </div>
        </div>
      </div>
    </main>
  );
}
